<template>
  <div v-if="isLoaded">
    <studentHeader />
    <div class="user-flow-logo">
      <div v-if="step !== 6" class="user-splash"></div>
      <div
        class="alert alert-success container-narrow mx-auto banner"
        v-show="userDetails.showVerificationMessage && step === 6"
        role="alert"
      >
        Your account has been verified
      </div>
      <div class="user-flow" :style="{ maxWidth: width }">
        <div class="row">
          <!-- We are going to handle steps conditionally in this component based on a step counter which we will increment a  -->
          <StudentWelcome
            v-if="step === 1"
            :progress="17"
            :userDetails="userDetails"
            :handleSubmit="handleSubmit"
          >
          </StudentWelcome>
          <StudentEducation
            v-if="step === 2"
            :progress="33"
            :userDetails="userDetails"
            @handle-submit="handleSubmit"
            :handleBack="handleBack"
          ></StudentEducation>
          <StudentResearch
            v-if="step === 3"
            :progress="50"
            :error="error"
            :userDetails="userDetails"
            :handleSubmit="handleSubmit"
            :handleBack="handleBack"
          ></StudentResearch>
          <StudentThesis
            v-if="step === 4"
            :progress="75"
            :error="error"
            :userDetails="userDetails"
            :handleSubmit="handleSubmit"
            :handleBack="handleBack"
          ></StudentThesis>
          <StudentFinal
            v-if="step === 5"
            :progress="83"
            :userDetails="userDetails"
            :verifyMessageError="verifyMessageError"
            :handleSubmit="handleSubmit"
            :handleBack="handleBack"
            :resendVerificationEmail="resendVerificationEmail"
            :verifyMessageSuccess="verifyMessageSuccess"
          ></StudentFinal>
          <StudentDidYouKnow
            v-if="step === 6"
            :userDetails="userDetails"
            :progress="100"
            :handleSubmit="handleSubmit"
            :handleBack="handleBack"
          >
          </StudentDidYouKnow>
          <student-explorer
            v-if="step === 7"
            :progress="0"
            :handleSubmit="handleSubmit"
            :handleBack="handleBack"
          ></student-explorer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentWelcome from '../../components/student/StudentWelcome.vue';
import StudentEducation from '../../components/student/StudentEducation.vue';
import StudentResearch from '../../components/student/StudentResearch.vue';
import StudentThesis from '../../components/student/StudentThesis.vue';
import StudentFinal from '../../components/student/StudentFinal.vue';
import StudentDidYouKnow from '../../components/student/StudentDidYouKnow.vue';
import StudentHeader from '../../components/student/StudentHeader.vue';
import StudentExplorer from '../../components/student/StudentExplorer.vue';

import { mapState } from 'vuex';

export default {
  name: 'StudentRegister',
  components: {
    StudentWelcome,
    StudentEducation,
    StudentResearch,
    StudentThesis,
    StudentFinal,
    StudentDidYouKnow,
    StudentHeader,
    StudentExplorer,
  },
  data: function () {
    return {
      userDetails: {},
      verifyMessageError: null,
      error: null,
      step: 1, // always set to 1 by default
      verifyMessageSuccess: null,
    };
  },
  updated() {
    var vm = this;
    if(this.userDetails.showVerificationMessage) {
      setTimeout(() => {
        this.$store.dispatch('updateUserDetails', {
          ...this.userDetails,
          showVerificationMessage: false,
        });
      }, 3000)
    }
  },
  computed: {
    ...mapState(['user']),
    width() {
      switch (this.step) {
        case 3:
          return '100%';
        case 4:
          return '100%';
        case 6:
          return '70%';
        default:
          return '50%';
      }
    },
    isLoaded() {
      var vm = this;
      var result = false;
      if (this.user.userId !== null) {
        var userInfo = JSON.parse(JSON.stringify(vm.$store.state.user));
        vm.userDetails = userInfo;
      }
      return true;
    },
  },
  methods: {
    previousStep: function () {
      this.step--;
    },
    nextStep: function () {
      this.step++;
    },
    handleSubmit(step) {
      const vm = this;
      vm.error = null;
      switch (step) {
        case 'basic details':
          // vm.$store.dispatch('updateUserDetails', {firstName: vm.userDetails.firstName, lastName: vm.userDetails.lastName, phoneNumber: vm.userDetails.phoneNumber, userId: vm.userDetails.userId}).then(() =>{
          vm.$store.dispatch('updateUserDetails', vm.userDetails).then(() => {
            this.nextStep();
          });
          break;
        case 'education':
          if (vm.userDetails.researchInterests === null || typeof vm.userDetails.researchInterests === typeof undefined) {
            vm.userDetails.researchInterests = [];
          }
          vm.$store.dispatch('updateUserDetails', vm.userDetails).then(() => {
            this.nextStep();
          });
          break;
        case 'research':
          if (this.userDetails.researchInterests.length === 0) {
            this.error = 'Please select a research area';
            return;
          }
          if (!this.userDetails.thesisStatus){
            return vm.$store.dispatch('updateUserDetails', {...vm.userDetails, thesisStatus: null}).then(() => {
            this.nextStep();
          });
          }
          vm.$store.dispatch('updateUserDetails', vm.userDetails).then(() => {
            this.nextStep();
          });
          break;
        case 'thesis':
          if (
            !this.userDetails.thesisStatus ||
            this.userDetails.thesisStatus < 1
          ) {
            this.error = 'Please select how far you are on your thesis journey';
            return;
          }
          if (!this.userDetails.verified) {
            var verificationEmailData = {
              toEmail: this.userDetails.email,
              verificationCode: this.userDetails.verificationCode, // why is there no code generated in backend?
            };
            vm.$store.dispatch('sendVerificationMail', verificationEmailData);
          }
          if (vm.userDetails.status != 'ok' && vm.userDetails.verified) {
            vm.userDetails.status = 'ok'
          }

          vm.$store.dispatch('updateUserDetails', vm.userDetails).then(() => {
            if (this.userDetails.verified) {
              this.step = this.step + 2;
              return;
            }
            this.nextStep();
          });
          break;
        case 'verification':
          this.verifyMessageSuccess = null;
          var enteredVerificationCode = vm.userDetails.enteredCode;
          var userVerificationCode = vm.userDetails.verificationCode;
          if (+enteredVerificationCode !== +userVerificationCode) {
            vm.verifyMessageError =
              'No match. Please check your code and try again.';
            return;
          }
          vm.$store.dispatch('updateUserVerification', true);
          vm.$store
            .dispatch('updateUserDetails', {
              ...vm.userDetails,
              showVerificationMessage: true,
              status: 'ok',
              signupStep: 2,
            })
            .then(() => {
              this.nextStep();
            });
          break;
        case 'did you know':
          localStorage.removeItem('notSignedUp')
          vm.$store.dispatch('updateUserDetails', {...vm.userDetails, currentProgress: 0})
          this.nextStep();
          break;
        default:
          break;
      }
    },
    handleBack(event) {
      event.preventDefault();
      this.previousStep();
      this.verifyMessageSuccess = null;
      this.verifyMessageError = null;
    },
    resendVerificationEmail() {
      var vm = this;
      const verificationEmailData = {
        toEmail: this.user.email,
        verificationCode: this.user.verificationCode,
      };
      // console.log("resendVerificationEmail: " + JSON.stringify(verificationEmailData));
      vm.$store
        .dispatch('sendVerificationMail', verificationEmailData)
        .then(() => {
          vm.verifyMessageSuccess = 'Check your inbox';
        });
    },
  },
};
</script>

<style>
.btn-outline {
  border: 1px solid #d22d89;
  color: #d22d89;
  padding: 0.4rem 4rem;
  font-size: 16px;
  font-weight: 600;
}

.user-flow .user-form form {
  padding: 50px 30px 30px 30px !important;
}
.w-200 {
  width: 200px;
  height: 40px;
}

.form-control {
  color: #495057 !important;
}

.welcome-desc {
  margin-bottom: 50px;
}
.user-flow {
  border-radius: 10px !important;
}
@media (max-width: 767px) {
  .header-text {
    font-size: 24px !important;
  }
  .user-flow .user-form form {
    padding: 0 !important;
  }
  .common-text {
    font-size: 24px;
    margin-bottom: 15px;
    margin-bottom: 46px;
  }
  .progress-spacing {
    margin: 25px 0px 50px !important;
    width: 100%;
  }
  #app #app-master #page {
    background-image: none !important;
  }
}
@media (max-width: 500px) {
  .bottom_button_group {
    position: fixed;
    bottom: 15px;
    left: 15px;
    right: 15px;
    margin: 0 auto;
    max-width: 100%;
  }
  .alert-success {
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .banner {
    display: none
  }
}
</style>

<style scoped>
h4 {
  font-size: 2.125rem;
}

/* .user-flow{
  max-width: 100%;
} */
.user-flow-logo {
  padding: 60px 0 40px 0;
  margin-top: 35px;
}

@media (max-width: 767px) {
  .user-flow {
    max-width: 100% !important;
  }

  .user-splash {
    display: none;
  }

  .user-flow {
    border: 0;
    box-shadow: none;
  }
  .user-flow-logo {
    padding: 40px 0 40px 0;
    margin-top: 0px;
  }
}
</style>
