<template>
  <div class="col-12 user-form">
    <form @submit.prevent="handleSubmit('verification')">
      <h1 class="text-uppercase common-text">FINAL STEP</h1>
      <ProgressBar :currentValue="progress"></ProgressBar>
      <h4 class="mb-4 font-weight-normal">
        We have sent a 6-digit verification code to your email.
      </h4>
      <h4 class="welcome-desc font-weight-normal">
        Enter the code below to verify your profile.
      </h4>
      <div class="form-group text-left">
        <label>Verify profile</label>
        <input
          type="text"
          required
          v-model="userDetails.enteredCode"
          name="code"
          class="form-control form-control-lg input-color"
          placeholder="Verification code"
        />
        <span class="text-error">{{ failCode }}</span>
        <div class="alert alert-danger mt-3" v-if="verifyMessageError">
          {{ verifyMessageError }}
        </div>
        <div class="alert alert-success mt-3" v-if="verifyMessageSuccess">
          {{ verifyMessageSuccess }}
        </div>
      </div>
      <div class="text-right">
        <button
        type="button"
          class="btn Explorer-FinalStep-Resend text-right color-third"
          @click="resendVerificationEmail"
        >
          Resend code
        </button>
      </div>
      <div class="form-group">
        <div class="custom-control custom-checkbox mb-1 text-left">
          <input
            type="checkbox"
            class="custom-control-input"
            name="role"
            id="signupTerms"
            v-model="terms"
            value="terms"
            required
          />
          <label class="custom-control-label" for="signupTerms"
            >I accept
            <router-link
              to="/user/terms"
              target="_blank"
              class="color-third Explorer-FinalStep-Terms"
              >the terms and conditions</router-link
            ></label
          >
        </div>
      </div>
      <div class="d-flex justify-content-between mt-5 bottom_button_group">
        <button
          class="btn btn-link Explorer-FinalStep-Back"
          @click="handleBack"
        >
          <i class="fas fa-chevron-left"></i> Back
        </button>
        <button class="btn btn-primary ml-2 Explorer-FinalStep-Next w-200">
          Verify my profile
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import ProgressBar from '../ProgressBar.vue';

export default {
  name: 'StudentFinal',
  components: {
    ProgressBar,
  },
  props: [
    'userDetails',
    'handleSubmit',
    'progress',
    'handleBack',
    'verifyMessageError',
    'verifyMessageSuccess',
    'resendVerificationEmail',
  ],
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.form-group {
  margin-bottom: 0;
}
@media (max-width: 499px) {
  .common-text {
    font-size: 24px;
  }
}
</style>
