<template>
  <div class="col-12 user-form">
    <form action="">
      <h1 class="text-uppercase common-text">CONGRATULATIONS</h1>
      <p style="font-size: 14px" class="font-weight-normal">
        Your profile level is now explorer
      </p>
      <h1 class="header-text" style="">EXPLORER</h1>
      <img
        src="~@/assets/search-big-purple.png"
        alt="search icon"
        class="m-26"
        srcset=""
      />
      <p class="body-text">
        You can now search inspiration, see student thesis topics, and get
        professional guidance on your thesis project.
      </p>
      <strong class="body-text">Ready to be matched with companies?</strong>
      <p class="body-text bottom-spacing">
        Upgrade your profile with more information to reach next level before we
        can match you with companies.
      </p>
      <rising-star-progress-bar
        :currentValue="progress"
      ></rising-star-progress-bar>
      <div  :class="{bottom_button_group: isContentLong}">
        <router-link
          to="/user/student-rising-star"
          class="btn btn-block btn-primary Explorer-Congratulations-Next"
          >Upgrade profile to be matched</router-link
        >
        <router-link
          class="btn btn-block button-outline Explorer-Congratulations-Dashboard"
          to="/"
          >Go to Explorer dashboard</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import RisingStarProgressBar from '../RisingStarProgressBar.vue';

export default {
  name: 'StudentExplorer',
  components: { RisingStarProgressBar },
  props: ['progress'],
  data() {
    return {
      isContentLong: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    const contentHeight = this.$refs.content.offsetHeight + 80; // get height of your content element
    const viewportHeight = window.innerHeight; // get height of device viewport
    // console.log(viewportHeight, contentHeight+80)
    if (contentHeight > viewportHeight) {
      this.isContentLong = true;
      return;
    }
    this.isContentLong = false;
  },
};
</script>

<style scoped>
.button-outline {
  border: 2px solid #5a2871;
}
.header-text {
  font-size: 54px!important;
  color: #5a2871;
  font-weight: 700;
}
.m-26 {
  margin-top: 26px;
  margin-bottom: 30px;
}
.body-text {
  font-size: 18px;
}
  .bottom-spacing{
    margin-bottom: 40px;
  }
@media (max-width: 499px) {
  .common-text {
    margin-bottom: 10px;
  }
  .bottom-spacing{
    margin-bottom: 60px;
  }
}
</style>

<style>
@media (max-width: 499px) {
    .lets_test_this {
    margin-left: 18px;
  }
}
</style>
