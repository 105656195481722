<template>
  <div class="col-12 user-form" ref="content" v-show="handleAnimation">
    <form @submit.prevent="handleSubmit('thesis')">
      <h1 class="text-uppercase common-text">YOUR THESIS PROGRESS</h1>
      <ProgressBar :currentValue="progress"></ProgressBar>
      <h4 class="welcome-desc font-weight-normal">
        Tell us a bit more about your thesis project situation so we can guide
        and help you reach the next step on your journey.
        <br /><br />
        Click below to let us know where you are today.
      </h4>

      <div class="container stepper-container">
        <div class="mb-3 mt-3">
          <div
            class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x"
          >
            <a
              v-on:click="setSelected(1)"
              class="step"
              v-bind:class="{ completed: userDetails.thesisStatus === 1 }"
            >
              <div class="step-icon-wrap">
                <div class="step-icon icon_1"></div>
              </div>
              <div class="form-info step-above">
                <div class="arrow-up"></div>
                <div>
                  <h4 class="step-title">INSPIRATION</h4>
                  <p>
                    I'm currently searching for inspiration and/or guidance for
                    my thesis project.
                  </p>
                </div>
              </div>
            </a>
            <div
              v-on:click="setSelected(2)"
              class="step"
              v-bind:class="{ completed: userDetails.thesisStatus === 2 }"
            >
              <div class="step-icon-wrap">
                <div class="step-icon icon_2"></div>
              </div>

              <!-- this is written only for visual purposes to show equal verticaly space between steps -->
              <div class="form-info step-above" style="opacity: 0">
                <div class="arrow-up"></div>
                <div>
                  <h4 class="step-title">INSPIRATION</h4>
                  <p>
                    I'm currently searching for inspiration and/or guidance for
                    my thesis project.
                  </p>
                </div>
              </div>
            </div>
            <a
              v-on:click="setSelected(3)"
              class="step"
              v-bind:class="{ completed: userDetails.thesisStatus === 3 }"
            >
              <div class="step-icon-wrap">
                <div class="step-icon icon_3"></div>
              </div>
              <div class="form-info">
                <div class="arrow-up"></div>
                <div>
                  <h4 class="step-title">IDEAS ARE FORMING</h4>
                  <p>
                    Thesis ideas are starting to form but I'm not settled on a
                    topic for my thesis project yet.
                  </p>
                </div>
              </div>
            </a>
            <div
              v-on:click="setSelected(4)"
              class="step"
              v-bind:class="{ completed: userDetails.thesisStatus === 4 }"
            >
              <div class="step-icon-wrap">
                <div class="step-icon icon_4"></div>
              </div>
              <!-- this is written only for visual purposes to show equal verticaly space between steps -->
              <div class="form-info step-above" style="opacity: 0">
                <div class="arrow-up"></div>
                <div>
                  <h4 class="step-title">INSPIRATION</h4>
                  <p>
                    I'm currently searching for inspiration and/or guidance for
                    my thesis project.
                  </p>
                </div>
              </div>
            </div>
            <a
              v-on:click="setSelected(5)"
              class="step"
              v-bind:class="{ completed: userDetails.thesisStatus === 5 }"
            >
              <div class="step-icon-wrap last">
                <div class="step-icon icon_5"></div>
              </div>
              <div class="form-info">
                <div class="arrow-up"></div>
                <div>
                  <h4 class="step-title">READY TO BE MATCHED</h4>
                  <p>
                    I'm settled on a thesis topic and ready to be matched with
                    relevant companies.
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div
        class="alert alert-danger mt-3"
        v-if="error && !userDetails.thesisStatus"
      >
        {{ error }}
      </div>
      <div
        class="d-flex justify-content-between mt-5"
        :class="{
          bottom_button_group:
            !isContentLong && !error && !userDetails.thesisStatus,
        }"
      >
        <button
          class="btn btn-link Explorer-YourThesisProcess-Back"
          @click="handleBack"
        >
          <i class="fas fa-chevron-left"></i> Back
        </button>
        <button class="btn btn-primary Explorer-YourThesisProcess-Next w-200">
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import ProgressBar from '../ProgressBar.vue';

import 'animate.css';

export default {
  name: 'StudentThesis',
  data() {
    return {
      isContentLong: false,
    };
  },
  components: {
    ProgressBar,
  },
  props: ['userDetails', 'handleSubmit', 'progress', 'handleBack', 'error'],
  computed: {
    handleAnimation() {
      // setTimeout(() => {
      this.animateCSS('.icon_1', 'bounceIn');
      // }, 200);
      // setTimeout(() => {
      this.animateCSS('.icon_2', 'bounceIn');
      // }, 400);
      // setTimeout(() => {
      this.animateCSS('.icon_3', 'bounceIn');
      // }, 600);
      // setTimeout(() => {
      this.animateCSS('.icon_4', 'bounceIn');
      // }, 800);
      // setTimeout(() => {
      this.animateCSS('.icon_5', 'bounceIn');
      // }, 1000);
      return true;
    },
  },
  methods: {
    setSelected(id) {
      this.userDetails.thesisStatus = id;
    },
    animateCSS(element, animation, prefix = 'animate__') {
      // We create a Promise and return it
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const animationName = `${prefix}${animation}`;
          const node = document.querySelector(element);

          node.classList.add(`${prefix}animated`, animationName);

          // When the animation ends, we clean the classes and resolve the Promise
          function handleAnimationEnd(event) {
            event.stopPropagation();
            node.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
          }

          node.addEventListener('animationend', handleAnimationEnd, {
            once: true,
          });
        });
      }, 4000);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    const contentHeight = this.$refs.content.offsetHeight + 80; // get height of your content element
    const viewportHeight = window.innerHeight; // get height of device viewport
    // console.log(viewportHeight, contentHeight+80)
    if (contentHeight > viewportHeight) {
      this.isContentLong = true;
      return;
    }
    this.isContentLong = false;

    // this.handleAnimation();
  },
};
</script>

<style scoped>
.stepper-container {
  margin-top: 30px;
  margin-bottom: 30px;
}
.steps .step {
  display: block;
  width: 100%;
  /* margin-bottom: 35px; */
  text-align: center;
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 30px;
  text-align: center;
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 1px;
  margin-top: -1px;
  background-color: #5a2871;
  content: '';
  z-index: 1;
}
.steps .step.completed .step-icon:before {
  content: ' ';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 3px solid #fff;
  border-radius: 50%;
}
.steps .step:hover {
  text-decoration: none;
}
.steps .step .step-icon-wrap::before {
  left: 0;
}

.steps .step .step-icon-wrap::after {
  right: 0;
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  border: 4px solid #5a2871;
  color: #fff;
  border-radius: 50%;
  background-color: #fff;
  color: #374250;
  font-size: 38px;
  line-height: 81px;
  z-index: 5;
  cursor: pointer;
}

.steps .step .step-title {
  margin-top: 10px;
  color: #000;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
}

.form-info p {
  color: #000;
  font-size: 12px;
  font-weight: 400;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none;
}

.steps .step:last-child .step-icon-wrap::after {
  display: none;
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #5a2871;
}

.steps .step.completed .step-icon {
  background: #bc1e73;
  color: #fff;
}
.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid black;
  margin: auto;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .steps .step {
    display: flex;
  }
  .steps .step .step-icon-wrap {
    width: auto;
    height: 50px;
  }
  .steps .step .step-icon-wrap::before,
  .steps .step .step-icon-wrap::after {
    width: 1px;
    height: 100%;
    margin-top: -3px;
  }
  .steps .step .step-icon-wrap::after {
    left: 50%;
  }
  .steps .step .step-icon-wrap::before {
    left: 50%;
  }
  .form-info {
    display: flex;
    margin-top: 2px;
    column-gap: 8px;
  }
  .steps .step .step-title {
    margin-top: 0;
    text-align: left;
  }
  .form-info p {
    text-align: left;
    margin-bottom: 0;
  }
  .arrow-up {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 10px solid #000;
    margin: 8px 0 0 0;
  }
  .last:before {
    display: none !important;
  }
  .mobile_error {
  }
}
</style>
