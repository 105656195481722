import { render, staticRenderFns } from "./StudentRegister.vue?vue&type=template&id=558172f0&scoped=true&"
import script from "./StudentRegister.vue?vue&type=script&lang=js&"
export * from "./StudentRegister.vue?vue&type=script&lang=js&"
import style0 from "./StudentRegister.vue?vue&type=style&index=0&lang=css&"
import style1 from "./StudentRegister.vue?vue&type=style&index=1&id=558172f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "558172f0",
  null
  
)

export default component.exports