<template>
  <div class="col-12 user-form">
    <form @submit.prevent="validateForm">
      <h1 class="text-uppercase common-text">WELCOME TO EXCELERATE</h1>
      <ProgressBar :currentValue="progress"></ProgressBar>
      <h4 class="welcome-desc font-weight-normal">
        Tell us a bit more about yourself to get started using Excelerate.
      </h4>
      <div class="form-group text-left">
        <label for>First name</label>
        <input
          type="text"
          class="form-control form-control-lg"
          v-model="userDetails.firstName"
          autofocus
          required
        />
      </div>
      <div class="form-group text-left">
        <label for>Last name</label>
        <input
          type="text"
          class="form-control form-control-lg"
          v-model="userDetails.lastName"
          required
        />
      </div>
      <div>
        <div class="form-group text-left">
          <label for> Phone number </label>
          <input
            type="phone"
            class="form-control form-control-lg"
            v-model="userDetails.phoneNumber"
            placeholder="E.g. 11223344"
            required
          />
        </div>
        <div class="form-group text-left">
          <label for>You speak fluently</label>
          <!-- <select v-model="userDetails.language" required class="form-control">
            <option value="null" disabled>Select from list</option>
            <option
              v-for="(language, index) in languages"
              :key="index"
              :value="language.key"
            >
              {{ language.name }}
            </option>
          </select> -->
          <multiselect
            multiple
            v-model="userDetails.language"
            :options="languages"
            :showLabels="false"
            :searchable="false"
            :allow-empty="false"
            deselect-label="Can't remove this value"
            placeholder="Select one"
            track-by="name"
            label="name"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{
              option.name
            }}</template>
          </multiselect>
          <div class="alert alert-danger mt-3" v-if="noLanguage">Please select at least one language.</div>
        </div>
      </div>
      <div class="d-flex justify-content-end bottom_button_group">
        <button
          class="btn btn-primary Explorer-WelcomeToExcelerate-Next mt-5 w-200"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import ProgressBar from '../ProgressBar.vue';

export default {
  name: 'StudentWelcome',
  props: ['userDetails', 'handleSubmit', 'progress'],
  components: { ProgressBar, Multiselect },
  data: function () {
    return {
      noLanguage: false,
      languages: [
        { name: 'English', key: 'en' },
        { name: 'Dansk', key: 'da' },
        { name: 'German', key: 'ge' },
        { name: 'Norwegian', key: 'nw' },
        { name: 'Swedish', key: 'sw' },
      ],
    };
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
  validateForm(){
    if (!this.userDetails.language ||this.userDetails.language.length === 0) {
      this.noLanguage = true
    }else {
      this.handleSubmit('basic details')
    }
  }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.text-left label {
  text-align: left;
}
.dots_block {
  display: flex;
  column-gap: 1px;
}
.icon_box {
  border: 1px solid #eee;
  width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 50%;
}
.icon_box .fa-search {
  color: #aaa;
}
.block_box {
  width: 7px;
  height: 7px;
  background-color: #dbdbdb;
  display: block;
  border-radius: 5px;
}
.block_box_completed {
  width: 7px;
  height: 7px;
  background-color: #5a2871;
  display: block;
  border-radius: 5px;
}
.tooltip {
  position: relative;
  float: right;
}
.tooltip > .tooltip-inner {
  background-color: #eebf3f;
  padding: 5px 15px;
  color: rgb(23, 44, 66);
  font-weight: bold;
  font-size: 13px;
}
.popOver + .tooltip > .tooltip-arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #eebf3f;
}

.progress {
  border-radius: 0;
  overflow: visible;
}
.progress-bar {
  background: rgb(23, 44, 60);
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
}

/* @media (max-width: 767px) {
  .
} */
</style>
<style>
.multiselect__input {
  color: #5a2871;
}
.multiselect__option--highlight {
  background: #bd1a77;
  outline: none;
  color: #fff;
}
.multiselect {
  color: #5a2871;
  font-size: 14px;
  background: #fff;
}
.multiselect,
.multiselect__single {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}
.multiselect__single {
  margin-bottom: 0px;
}
.multiselect__tags {
  font-family: 'Poppins', sans-serif;
  color: #5a2871;
  font-size: 14px;
  border: 1px solid #dfd6df !important;
  border-radius: 4px;
  padding: 6px 20px 0 8px;
  min-height: 35px !important;
}
.multiselect__tag{
  color: #5a2871 !important;
  background: transparent !important;
}
.multiselect__tag-icon:after {
  color: #5a2871 !important;
}
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover{
  background: transparent !important;
}
.multiselect__option--selected.multiselect__option--highlight {
  background-color: #5a2871;
}
.multiselect__placeholder {
  margin-bottom: 4px;
}
</style>
