s<template>
  <div class="col-12 user-form">
    <div v-if="loading" class="loader">Loading...</div>
    <form v-else @submit.prevent="submitForm">
      <h1 class="text-uppercase common-text">YOUR STUDY BACKGROUND</h1>
      <ProgressBar :currentValue="progress"></ProgressBar>
      <h4 class="welcome-desc font-weight-normal">
        Tell us a bit more about your study background so we can guide you in
        the right direction relevant to your study background.
      </h4>
      <div class="user-form-wizard" :class="userDetails.area ? '' : 'guide'">
        <div class="form-group text-left" @change="reset('institution')">
          <label>Your educational institution? *</label>
          <select
            class="form-control text-secondary"
            v-model="userDetails.institution"
            @change="getCurrentUniversityData()"
            required
          >
            <option
              value="null"
              disabled
              v-if="universities.universities.length !== 1"
            >
              Select from list
            </option>
            <option
              v-for="(institute, index) in universities.universities"
              :key="index"
              :value="{
                uid: institute.id,
                id: institute.university_id,
                name: institute.university_name,
              }"
            >
              {{ institute.university_name }}
            </option>
          </select>
        </div>
        <div
          class="pj-loading"
          v-if="!universityLoaded && userDetails.institution && userDetails.institution.name !== 'Other'"
        >
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
        <div class="form-group text-left"
        v-if="userDetails.institution && userDetails.institution.name === 'Other'"
        >
        <label for>Your study degree? *</label>
        <input
          type="text"
          class="form-control form-control-lg"
          v-model="userDetails.area"
          required
        />
        </div>
        <div
          class="form-group text-left"
          v-if="userDetails.institution && universityLoaded && userDetails.institution.name !== 'Other'"
        >
          <label>Your study degree? *</label>
          <autocomplete
            :search="searchArea"
            required
            placeholder="Search and select"
            aria-label="Search and select"
            class="hard-skills"
            :get-result-value="getResultValue"
            @keydown.enter.prevent
            @submit="setAreaForUser"
            v-bind="{'default-value':userDetails.area}"
          ></autocomplete>
        </div>
        <!-- <div
          class="form-group text-left"
          v-if="
            userDetails.institution &&
            userDetails.institution.uid &&
            userDetails.institution.uid == 1 &&
            universityLoaded
          "
        >
          <label>Your study degree *</label>
          <autocomplete
            :search="searchArea"
            required
            placeholder="Search and select"
            aria-label="Search and select"
            class="hard-skills"
            :get-result-value="getResultValue"
            @submit="setFirstSpecialization"
            v-bind:default-value="userDetails.secondaryArea"
          ></autocomplete>
        </div> -->
        <div
          class="form-group text-left"
          v-if="
            userDetails.hasSubProgramme &&
            userDetails.institution.name === 'Roskilde University'
          "
        >
          <label>Specialization course #1? *</label>
          <autocomplete
            :search="searchSubProgramme"
            required
            placeholder="Search and select"
            aria-label="Search and select"
            class="hard-skills"
            :get-result-value="getSubProgrammeValue"
            @keydown.enter.prevent
            @submit="setFirstSpecialization"
            v-bind="{'default-value':userDetails.specializationCourseOne}"
          ></autocomplete>
        </div>
        <div
          class="form-group text-left"
          v-if="
            userDetails.hasSubProgramme &&
            this.userDetails.institution.name === 'Roskilde University'
          "
        >
          <label>Specialization course #2? *</label>
          <autocomplete
            :search="searchSubProgramme"
            required
            placeholder="Search and select"
            aria-label="Search and select"
            class="hard-skills"
            :get-result-value="getSubProgrammeValue"
            @submit="setSecondSpecialization"
            @keydown.enter.prevent
            v-bind="{'default-value':userDetails.specializationCourseTwo}"
          ></autocomplete>
        </div>
      </div>
      <div
        class=""
        v-if="userDetails.institution && universityLoaded && userDetails.area"
      >
        <hr class="mt-4" />
        <div class="form-group text-left">
          <label>Which semester are you in? *</label>
          <select
            v-if="isBachelor && !isOther"
            v-model="userDetails.currentSemester"
            class="form-control"
            required
          >
            <!-- <option value="null" disabled>Select from list</option> -->
            <option
              v-for="(semester, index) in [1, 2, 3, 4, 5, 6]"
              :key="index"
            >
              {{ semester }}
            </option>
          </select>
          <select
            v-if="isBachelor && isOther"
            v-model="userDetails.currentSemester"
            class="form-control"
            required
          >
            <!-- <option value="null" disabled>Select from list</option> -->
            <option
              v-for="(semester, index) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              :key="index"
            >
              {{ semester }}
            </option>
          </select>
          <select
            v-if="!isBachelor && !isOther"
            v-model="userDetails.currentSemester"
            class="form-control"
            required
          >
            <!-- <option value="null" disabled>Select from list</option> -->
            <option v-for="(semester, index) in [1, 2, 3, 4]" :key="index">
              {{ semester }}
            </option>
          </select>
        </div>
        <div class="form-group text-left">
          <label>Which semester do you graduate? *</label>
          <select
            v-model="userDetails.graduatingSemester"
            class="form-control"
            required
          >
            <option v-for="(semester, index) in semesters" :key="index">
              {{ semester.value }}
            </option>
          </select>
        </div>
      </div>

      <div class="d-flex justify-content-between mt-5 bottom_button_group">
        <button
          class="btn btn-link Explorer-YourStudyBackground-Back"
          @click="handleBack"
        >
          <i class="fas fa-chevron-left"></i> Back
        </button>
        <button class="btn btn-primary Explorer-YourStudyBackground-Next w-200">
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';

import json_semesters from '@/json/semesters.json';
import ProgressBar from '../ProgressBar.vue';
import { HTTP } from '../../axios/axios';

import '@trevoreyre/autocomplete-vue/dist/style.css';

export default {
  name: 'StudentEducation',
  components: {
    Autocomplete,
    ProgressBar,
  },
  props: ['userDetails', 'handleSubmit', 'progress', 'handleBack'],
  data() {
    return {
      universityData: {},
      semesters: json_semesters,
      loading: false,
      universityLoaded: false,
      subProgrammeList: [],
    };
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.$store.dispatch('getUniversityData');
    if (this.userDetails.institution.uid) {
      this.getCurrentUniversityData();
      this.loading = false;
    }
  },
  computed: {
    universities: function () {
      return this.$store.getters.universities;
    },
    isBachelor() {
        if(this.userDetails.degree) return this.userDetails.degree.includes('Bachelor') || this.userDetails.degree.includes('HA')
        return null
    },
    isOther() {
      if(this.userDetails.degree) return this.userDetails.degree.includes('HA');
      return null
    },
  },
  methods: {
    //this method will be removed in package 11
    reset: function (target) {
      if (target == 'country') {
        this.userDetails.institution = '';
        this.userDetails.faculty = '';
        this.userDetails.degree = '';
        this.userDetails.area = '';
      }
      if (target == 'institution') {
        this.userDetails.faculty = '';
        this.userDetails.degree = '';
        this.userDetails.area = '';
      }
      if (target == 'faculty') {
        this.userDetails.degree = '';
        this.userDetails.area = '';
      }
      if (target == 'degree') {
        this.userDetails.area = '';
      }
    },
    async getCurrentUniversityData() {
      this.userDetails.secondaryArea = null;
      this.userDetails.area = null;
      // this.universityLoaded = true;
      try {
        const res = await HTTP.get(
          `getUniversityDataListing/${this.userDetails.institution.uid}`
        );
        if (res.status == 200) {
          // no idea wtf this data is now. Random naming with random data is what we have here
          this.universityLoaded = true;
          this.universityData = res.data.payload;
          if (this.userDetails.hasSubProgramme) {
            const userProgram = this.universityData.programmes.filter(
              (program) => program.programme_name === this.userDetails.area
            );
            // console.log("🚀 ~ file: StudentEducation.vue:229 ~ getCurrentUniversityData ~ userProgram:", userProgram[0].subProgrammesList)
            this.subProgrammeList = userProgram[0].subProgrammesList;
          }
        }
      } catch (error) {
        console.log('err');
      }
    },
    searchArea(input) {
      const vm = this;
      this.userDetails.customProgramme = true;
      if (input.length < 1) {
        return [];
      }
      return this.universityData.programmes.filter((area) => {
        return area.programme_name.toLowerCase().includes(input.toLowerCase());
      });
    },
    searchSubProgramme(input) {
      const vm = this;
      this.userDetails.customProgramme = true;
      if (input.length < 1) {
        return [];
      }
      return this.subProgrammeList.filter((area) => {
        return area.subProgrammeName
          .toLowerCase()
          .includes(input.toLowerCase());
      });
    },
    getResultValue(result) {
      this.userDetails.customProgramme = false;
      return result.programme_name;
    },
    getSubProgrammeValue(result) {
      this.userDetails.customProgramme = false;
      return result.subProgrammeName;
    },
    setAreaForUser(area) {
      this.userDetails.hasSubProgramme = false;
      if(!area) return
      if (area['subProgrammesList']) {
        this.userDetails.hasSubProgramme = true;
        this.subProgrammeList = area['subProgrammesList'];
      }
      this.userDetails.area = area.programme_name;
      // now we get the students degree details once they select an area
      this.userDetails.degree = Object.values(
        this.universityData.degrees
      ).filter((item) => item.id === area.degree_id)[0].degree_name;
      this.userDetails.faculty = Object.values(
        this.universityData.faculties
      ).filter((item) => item.faculty_id === area.faculty_id)[0].faculty_name;
    },
    setFirstSpecialization(area) {
      this.userDetails.specializationCourseOne = area.subProgrammeName;
    },
    setSecondSpecialization(area) {
      this.userDetails.specializationCourseTwo = area.subProgrammeName;
    },
    submitForm(){
      if(this.isBachelor) {
        this.userDetails.currentDegree = 'bachelor'
      }else{
        this.userDetails.currentDegree = 'master'
      }
      this.$emit('handle-submit', 'education')
    }
  },
};
</script>

<style scoped>
.autocomplete-input {
  color: #495057 !important;
}
.autocomplete .autocomplete-result-list li {
  color: #495057 !important;
}
</style>
