<template>
  <div class="col-12 user-form">
    <div v-if="loading" class="loader">Loading...</div>
    <form v-else @submit.prevent="handleSubmit('research')">
      <h1 class="text-uppercase common-text">YOUR RESEARCH INTERESTS</h1>
      <ProgressBar :currentValue="progress"></ProgressBar>
      <h4 class="welcome-desc font-weight-normal">
        Which research area(s) interest you? Click to select.
      </h4>
      <div class="main-card-box">
        <div
          class="card card-custom"
          v-bind:class="{
            active_card:
              userDetails.researchInterests &&
              userDetails.researchInterests.includes(item.id),
          }"
          v-for="item in categoryList"
          :key="item.id"
          @click="toggleSelect(item.id)"
        >
          <div class="card-body d-flex custom-card-body">
            <div
              class=""
              v-if="
                userDetails.researchInterests &&
                userDetails.researchInterests.includes(item.id)
              "
            >
              <img
                class="image-list"
                v-bind:src="item.lite_image"
                v-bind:alt="item.category_name"
              />
            </div>
            <div class="" v-else>
              <img
                class="image-list"
                v-bind:src="item.image"
                v-bind:alt="item.category_name"
              />
            </div>
            <h6
              class="card-subtitle text-muted pl-2"
              v-bind:class="{
                active_card_text:
                  userDetails.researchInterests &&
                  userDetails.researchInterests.includes(item.id),
              }"
            >
              {{ item.category_name }}
            </h6>
          </div>
        </div>
      </div>
      <div
        class="alert alert-danger mt-3"
        v-if="error && userDetails.researchInterests.length === 0"
      >
        {{ error }}
      </div>
      <div class="d-flex justify-content-between mt-5 button_group">
        <button
          class="btn btn-link Explorer-YourResearchInterests-Back"
          @click="handleBack"
        >
          <i class="fas fa-chevron-left"></i> Back
        </button>
        <button
          class="btn btn-primary Explorer-YourResearchInterests-Next w-200"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { HTTP } from '../../axios/axios';
import ProgressBar from '../ProgressBar.vue';
export default {
  name: 'StudentResearch',
  data() {
    return {
      categoryList: null,
      loading: true,
    };
  },
  components: {
    ProgressBar,
  },
  props: ['userDetails', 'handleSubmit', 'progress', 'handleBack', 'error'],
  async mounted() {
    window.scrollTo(0, 0);
    try {
      const res = await HTTP.get(`get-categories`);
      if (res.status == 200) {
        // no idea wtf this data is now. Random naming with random data is what we have here
        this.loading = false;
        this.categoryList = res.data.data.payload;
      }
    } catch (error) {
      console.log('err');
    }
  },
  methods: {
    toggleSelect(id) {
      const item = this.userDetails.researchInterests.find(
        (item) => item === id
      );
      if (item) {
        this.userDetails.researchInterests =
          this.userDetails.researchInterests.filter((item) => item !== id);
      } else {
        this.userDetails.researchInterests.push(id);
      }
    },
  },
};
</script>

<style scoped>
.image-list {
  width: 70px;
  /* height: 100px; */
}

.main-card-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  width: 100%;
  justify-content: center;
}
.card-custom {
  box-shadow: none;
  width: 24%;
  border-radius: 10px;
  background-color: #dbdbdb;
}
.card {
  margin-bottom: 10px;
  cursor: pointer;
}
.card-body {
  align-items: center;
}
.card-subtitle {
  margin: 5px 5px !important;
  color: #9898a9 !important;
  font-weight: 600;
  text-align: left;
  font-size: 14px !important;
  text-transform: uppercase;
  text-overflow: wrap;
}
.card-custom:hover {
  /* border: 1px solid #bc1e73 !important; */
  /* background: #BC1E73; */
}

.card-custom:hover .custom-card-body h4 {
  /* color: #fff; */
}

.active_card {
  background: #bc1e73;
  border: 1px solid #bc1e73 !important;
}
.active_card_text {
  color: #fff !important;
  font-weight: 600;
  font-size: 14px !important;
  text-transform: uppercase;
}

@media (max-width: 390px) {
  /* .image-list {
    width: 30px;
  } */
  .card-custom {
    width: 100% !important;
    margin-bottom: 12px;
  }
}
/* @media (max-width: 767px) { */
@media (max-width: 1200px) {
  /* Divyansh will help figure this part out */
  .image-list {
    width: 35px;
  }
  .card {
    border: none !important;
  }
  .footer-image-list {
    flex-direction: row;
    position: relative;
  }
  .main-card-box {
    justify-content: space-between;
  }
  .card-custom {
    width: 48%;
    margin-bottom: 12px;
  }
  .custom-card-body {
    padding: 10px;
    /* background: #efefef; */
    justify-content: start;
  }
  .card-subtitle {
    font-size: 12px !important;
    margin: 5px 0px 5px 3px !important;
  }
}
</style>
