<template>
  <div class="progress-spacing">
    <div class="d-flex align-items-center">
      <div class="barWrapper">
        <div class="progress">
          <div
            class="progress-bar"
            :style="{ width: `${currentValue}%` }"
            role="progressbar"
          >
            <span
            id="prog"
              class="popOver"
              data-toggle="tooltip"
              data-placement="top"
              data-trigger="manual"
              :title="`${currentValue}%`"
            >
            </span>
            <div class="tooltip_box" ref="tooltip" :style="{left: `${currentValue - 5}%`}">
            <span class="popOver custom_tooltip" >{{ currentValue }}%</span>
            <div class="arrows-down"></div>
          </div>
          </div>
        </div>
      </div>
      <div class="icon_wrap" v-if="currentValue < 100">
        <img src="~@/assets/search-grey.png" alt="" srcset="" />
        <p class="icon_text">Explorer</p>
      </div>
      <div class="icon_wrap" v-else>
        <img src="~@/assets/search-purple.png" alt="" srcset="" />
        <p class="icon_text active-text">Explorer</p>
      </div>
      <div class="dots_block" v-if="currentValue < 100">
        <span class="block_box"></span>
        <span class="block_box"></span>
        <span class="block_box"></span>
      </div>
      <div class="dots_block" v-else>
        <span class="block_box_completed"></span>
        <span class="block_box_completed"></span>
        <span class="block_box_completed"></span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProgressBar',
  props: ['currentValue'],
};
</script>

<style scoped>
.tooltip {
  position: relative;
  float: right;
}
/* @media (min-width: 767px) {
.progress-spacing {
  margin: 70px auto;
  width: 487px;
}
} */
.progress-spacing {
  margin: 50px auto;
  max-width: 487px;
}
/* @media (max-width: 600px) {
.progress-spacing {
  margin-bottom: 20%
}
} */
.tooltip > .tooltip-inner {
  background-color: #eebf3f;
  padding: 5px 15px;
  color: #5a2871;
  font-weight: bold;
  font-size: 13px;
}
.active-text {
  color: #5a2871 !important;
}
.popOver + .tooltip > .tooltip-arrow {
  /* border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid #eebf3f; */
}

section {
  margin: 100px auto;
  height: 1000px;
}
.progress {
  /* border-radius: 5px; */
  overflow: visible;
  height: 6px;
  position: relative;
}
.active_second {
  color: #8a2372 !important;
}
.progress-bar {
  background: #5a2871;
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
  /* border-radius: 5px; */
}
.progress {
  background-color: #dbdbdb !important;
}
.icon_wrap {
  position: relative;
  color: #dbdbdb;
  font-weight: 600;
}
.icon_text {
  margin: 0;
  position: absolute;
  margin-top: 6px;
  margin-left: -6px;
  color: #dbdbdb;
  white-space: nowrap;
}
.barWrapper {
  width: 100%;
}
.tooltip_box{
  position: absolute;
    bottom: 25px;
}
.custom_tooltip {
    color: #fff;
    /* position: absolute;
    top: 14%; */
    background-color: #5A2871;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
}

.arrows-down {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #5a2871;
    position: absolute;
    left: 39%;
}
</style>
